import { createClient } from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

// 1. Create the Sanity client
export const client = createClient({
  // Replace with your actual project ID found at https://manage.sanity.io/ or in your sanity.config
  projectId: 'vvuwzk6u',
  
  // Replace with your dataset name (usually 'production')
  dataset: 'production',

  // Use a date-based API version string (e.g., today's date in YYYY-MM-DD format)
  apiVersion: '2023-02-01',

  // `true` for faster, cached reads in production; `false` if you want to ensure fresh data
  useCdn: true,
})

// 2. Create a helper function for generating image URLs
const builder = imageUrlBuilder(client)
export function urlFor(source) {
  return builder.image(source)
}
