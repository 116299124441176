// src/components/HeroSection.js
import React, { useEffect, useState } from 'react'
import { client } from '../lib/sanityClient'
import './HeroSection.css'

const titles = ["Welcome to RIVCON", "Your Project, Our Priority", "Building Excellence, Every Time"]
const subtitles = ["Building Dreams, Creating Reality", "Constructing with Precision", "Where Quality Meets Reliability"]

export default function HeroSection() {
  const [slides, setSlides] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [textAnimation, setTextAnimation] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      // Query each image field separately
      const query = `
        *[_type == "hero"][0]{
          "imgOneUrl": imageOne.asset->url,
          "imgTwoUrl": imageTwo.asset->url,
          "imgThreeUrl": imageThree.asset->url
        }
      `
      const result = await client.fetch(query)

      if (result) {
        // Build an array from however many images are present
        const tempSlides = []
        if (result.imgOneUrl) tempSlides.push({ imageUrl: result.imgOneUrl })
        if (result.imgTwoUrl) tempSlides.push({ imageUrl: result.imgTwoUrl })
        if (result.imgThreeUrl) tempSlides.push({ imageUrl: result.imgThreeUrl })

        setSlides(tempSlides)
      }
    }

    fetchData()
  }, [])

  // Slide rotation logic (every 5s)
  useEffect(() => {
    if (slides.length === 0) return

    const interval = setInterval(() => {
      setTextAnimation(true)
      setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % slides.length)
        setTextAnimation(false)
      }, 1500)
    }, 5000)

    return () => clearInterval(interval)
  }, [slides, currentIndex])

  if (slides.length === 0) {
    return (
      <section className="hero is-fullheight hero-background">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1>Loading hero slides...</h1>
          </div>
        </div>
      </section>
    )
  }

  const { imageUrl } = slides[currentIndex]

  return (
    <section
      className="hero is-fullheight hero-background"
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className="hero-body">
        <div className="overlay"></div>
        <div className="container has-text-centered">
          <div className={textAnimation ? 'fade-in' : ''}>
            <h1 className="title center-text">{titles[currentIndex]}</h1>
            <h2 className="subtitle center-text">{subtitles[currentIndex]}</h2>
          </div>
        </div>
      </div>
    </section>
  )
}
