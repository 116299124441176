import React, { useState, useEffect, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import "./BuildingAnimation.css"

// ----------------- Our Process Component -----------------
const OurProcess = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <section ref={ref} className={`our-process ${isVisible ? 'fade-in' : ''}`}>
      <h2>Our Process</h2>
      <p>
        Explore our <span className="highlight">comprehensive</span> approach from planning and contracting to finishing touches
      </p>
    </section>
  );
};

/**
 * Pre–Construction Phase: Draws the blueprint, guide lines and animated pencil.
 */
const PreConstruction = ({ scrollYProgress }) => {
  const preConstructionOpacity = useTransform(
    scrollYProgress,
    [0.15, 0.3, 0.3, 0.35],
    [1, 1, 0, 0]
  );
  const pencilProgress = useTransform(scrollYProgress, [0.15, 0.3], [0, 1]);
  const linePathLength = useTransform(scrollYProgress, [0.15, 0.3], [0, 1]);
  const pencilX = useTransform(pencilProgress, (value) => 90 + value * 280);
  const pencilY = useTransform(
    pencilProgress,
    (value) => 190 + 5 * Math.sin(value * 2 * Math.PI * 12)
  );

  return (
    <motion.g style={{ opacity: preConstructionOpacity }}>
      {/* Blueprint Outer Rectangle */}
      <rect
        x="75"
        y="75"
        width="310"
        height="160"
        fill="none"
        stroke="#0055ff"
        strokeWidth="2"
      />
      <motion.g style={{ opacity: preConstructionOpacity }}>
        {/* Blueprint Dashed Rectangle */}
        <rect
          x="80"
          y="80"
          width="300"
          height="150"
          fill="none"
          stroke="#0055ff"
          strokeDasharray="5,5"
          strokeWidth="2"
        />
        {/* Building Outline within the blueprint */}
        <motion.path
          d={`
            M90,150 L90,90 L180,90 L180,150 Z
            M90,115 L180,115 Z
            M120,115 L120,90
            M150,115 L150,90
            M110,150 L110,125
            M135,150 L135,125
            M160,150 L160,125
            M105,125 L165,125
          `}
          fill="#E6EBE4"
          stroke="#E67E22"
          strokeWidth="2"
          style={{ pathLength: linePathLength }}
        />
        {/* Static guide lines */}
        <line
          x1="200"
          y1="100"
          x2="370"
          y2="100"
          stroke="#0055ff"
          strokeWidth="1"
        />
        <line
          x1="200"
          y1="120"
          x2="370"
          y2="120"
          stroke="#0055ff"
          strokeWidth="1"
        />
        <line
          x1="200"
          y1="140"
          x2="370"
          y2="140"
          stroke="#0055ff"
          strokeWidth="1"
        />
        <line
          x1="200"
          y1="160"
          x2="370"
          y2="160"
          stroke="#0055ff"
          strokeWidth="1"
        />
        {/* Animated guide lines */}
        <motion.line
          x1="90"
          y1="180"
          x2="370"
          y2="180"
          stroke="#0055ff"
          strokeWidth="1"
          style={{ pathLength: linePathLength }}
        />
        <motion.line
          x1="90"
          y1="200"
          x2="370"
          y2="200"
          stroke="#0055ff"
          strokeWidth="1"
          style={{ pathLength: linePathLength }}
        />
        {/* Animated Pencil Icon */}
        <motion.g
          style={{
            x: pencilX,
            y: pencilY,
          }}
          animate={{ rotate: [-5, 5, -5] }}
          transition={{ duration: 1.5, repeat: Infinity, repeatType: "mirror" }}
        >
          {/* Pencil tip */}
          <polygon points="0,6 12,3 12,9" fill="#f5a623" />
          {/* Pencil body */}
          <rect x="12" y="3" width="25" height="6" fill="#ccc" />
          {/* Pencil eraser */}
          <rect x="37" y="3" width="5" height="6" fill="#000" />
        </motion.g>
      </motion.g>
    </motion.g>
  );
};

/**
 * Foundation Phase: Animates the foundation outline and fills it.
 */
const Foundation = ({ scrollYProgress }) => {
  const outlineFoundationProgress = useTransform(
    scrollYProgress,
    [0.3, 0.35],
    [0, 1]
  );
  const scaleFoundationY = useTransform(scrollYProgress, [0.4, 0.45], [1, 0.05]);
  const translateFoundationY = useTransform(
    scrollYProgress,
    [0.4, 0.45],
    [0, 200]
  );

  // Concrete pour animation - simplified transform
  const concreteHeight = useTransform(
    scrollYProgress,
    [0.36, 0.39],
    [0, 1]
  );

  const fillColor = useTransform(
    scrollYProgress,
    [0.35, 0.4],
    ["rgba(169, 169, 169, 0)", "rgba(169, 169, 169, 1)"]
  );

  const fenceOpacity = useTransform(
    scrollYProgress,
    [0.35, 0.36, 0.39, 0.4],
    [0, 1, 1, 0]
  );

  return (
    <motion.g
      style={{
        scaleY: scaleFoundationY,
        y: translateFoundationY,
        transformOrigin: "50% 100%",
        transformBox: "fill-box",
      }}
    >
      {/* Construction easement */}
      <motion.g style={{ opacity: fenceOpacity }}>
        {/* Perimeter fence - 20px offset from foundation */}
        <rect x="25" y="20" width="5" height="440" fill="#8B4513" />
        <rect x="495" y="20" width="5" height="440" fill="#8B4513" />
        
        {/* Horizontal supports */}
        <rect x="25" y="20" width="475" height="3" fill="#8B4513" />
        <rect x="25" y="35" width="475" height="3" fill="#8B4513" />
        <rect x="25" y="220" width="475" height="3" fill="#8B4513" />
        <rect x="25" y="440" width="475" height="3" fill="#8B4513" />
        
        {/* Cross braces */}
        <path d="M25,20 L495,440" stroke="#8B4513" strokeWidth="2" fill="none" />
        <path d="M495,20 L25,440" stroke="#8B4513" strokeWidth="2" fill="none" />
      </motion.g>

      {/* Foundation outline */}
      <motion.path
        d="M45,40 H475 V440 H45 Z"
        fill="none"
        stroke="#333"
        strokeWidth="4"
        style={{ pathLength: outlineFoundationProgress }}
      />

      {/* Concrete pour effect */}
      <motion.rect
        x="45"
        y="40"
        width="430"
        height="400"
        fill="rgba(169, 169, 169, 1)"
        style={{ 
          scaleY: concreteHeight,
          opacity: fillColor,
          transformOrigin: "top"
        }}
      />
    </motion.g>
  );
};

/**
 * Framing Phase: Draws the building outline and dividers.
 */
const Framing = ({ scrollYProgress }) => {
  const framingProgress = useTransform(scrollYProgress, [0.4, 0.55], [0, 1]);

  return (
    <>
      <motion.path
        d="M45 40 L475 40 L475 450 L45 450 Z"
        fill="none"
        stroke="#333"
        strokeWidth="4"
        style={{ pathLength: framingProgress }}
      />
      {/* Vertical dividers */}
      <motion.path
        d="M193 40 L193 450"
        fill="none"
        stroke="#333"
        strokeWidth="4"
        style={{ pathLength: framingProgress }}
      />
      <motion.path
        d="M327 40 L327 450"
        fill="none"
        stroke="#333"
        strokeWidth="4"
        style={{ pathLength: framingProgress }}
      />
      <motion.path
        d="M165 40 L165 450"
        fill="none"
        stroke="#333"
        strokeWidth="4"
        style={{ pathLength: framingProgress }}
      />
      <motion.path
        d="M355 40 L355 450"
        fill="none"
        stroke="#333"
        strokeWidth="4"
        style={{ pathLength: framingProgress }}
      />
      {/* Horizontal dividers */}
      <motion.path
        d="M45 220 L475 220"
        fill="none"
        stroke="#333"
        strokeWidth="4"
        style={{ pathLength: framingProgress }}
      />
      <motion.path
        d="M45 315 L475 315"
        fill="none"
        stroke="#333"
        strokeWidth="4"
        style={{ pathLength: framingProgress }}
      />
    </>
  );
};

/**
 * BrickRow renders one row of bricks.
 * - animationRow: 0 for bottom row, increasing upward.
 * - totalRows: total number of rows (used to compute the physical y coordinate)
 */
const BrickRow = ({
  animationRow,
  totalRows,
  numCols,
  brickWidth,
  brickHeight,
  offsetX,
  offsetY,
  scrollYProgress,
  start,
  rowSegment,
}) => {
  const rowStart = start + animationRow * rowSegment;
  const rowEnd = rowStart + rowSegment;
  const rowProgress = useTransform(scrollYProgress, [rowStart, rowEnd], [0, 1]);
  const translateY = useTransform(rowProgress, (v) => 20 * (1 - v));
  const physicalY = offsetY + (totalRows - 1 - animationRow) * brickHeight;

  return (
    <>
      {Array.from({ length: numCols }).map((_, colIndex) => {
        const x = offsetX + colIndex * brickWidth;
        const y = physicalY;
        return (
          <motion.rect
            key={`brick-${animationRow}-${colIndex}`}
            x={x}
            y={y}
            width={brickWidth}
            height={brickHeight}
            fill="#D8A39D"
            stroke="#A97166"
            strokeWidth="2"
            style={{
              opacity: rowProgress,
              translateY: translateY,
            }}
          />
        );
      })}
    </>
  );
};

/**
 * Wrapping renders the exterior fill as a grid of bricks that stack from the bottom up.
 */
const Wrapping = ({ scrollYProgress }) => {
  const offsetX = 45;
  const offsetY = 40;
  const totalWidth = 430;
  const totalHeight = 410;
  const numRows = 16;
  const numCols = 10;
  const brickWidth = totalWidth / numCols;
  const brickHeight = totalHeight / numRows;
  const start = 0.55;
  const end = 0.70;
  const totalRange = end - start;
  const rowSegment = totalRange / numRows;

  return (
    <motion.g>
      {Array.from({ length: numRows }).map((_, animationRow) => (
        <BrickRow
          key={`row-${animationRow}`}
          animationRow={animationRow}
          totalRows={numRows}
          numCols={numCols}
          brickWidth={brickWidth}
          brickHeight={brickHeight}
          offsetX={offsetX}
          offsetY={offsetY}
          scrollYProgress={scrollYProgress}
          start={start}
          rowSegment={rowSegment}
        />
      ))}
    </motion.g>
  );
};

const Sparkle = ({ x, y, delay = 0 }) => (
  <motion.polygon
    points="10,1 12,7 18,7 13,11 15,17 10,13 5,17 7,11 2,7 8,7"
    fill="#C0C0C0"
    stroke="#fff"
    strokeWidth="0.5"
    initial={{ scale: 0, opacity: 0 }}
    animate={{ scale: [0, 1, 0], opacity: [0, 1, 0] }}
    transition={{ duration: 2, ease: "easeInOut", repeat: Infinity, delay }}
    style={{
      transform: `translate(${x}px, ${y}px)`, // Move sparkle within the window
      transformOrigin: "center",
    }}
  />
);

const SparkleGroup = ({ width, height, count = 3 }) => {
  // Ensure sparkles are randomized correctly per window
  const [sparkles, setSparkles] = React.useState([]);

  React.useEffect(() => {
    setSparkles(
      Array.from({ length: count }).map(() => ({
        x: Math.random() * width * 0.8 + width * 0.1, // Keep sparkles within the window
        y: Math.random() * height * 0.8 + height * 0.1,
        delay: Math.random() * 0.5, // Random animation delay
      }))
    );
  }, [width, height, count]); // Ensures new sparkles generate when dimensions change

  return (
    <motion.g>
      {sparkles.map((sparkle, i) => (
        <Sparkle key={i} x={sparkle.x} y={sparkle.y} delay={sparkle.delay} />
      ))}
    </motion.g>
  );
};

const WindowsAndDoors = ({ scrollYProgress }) => {
  const totalRange = 0.15;
  const numElements = 6;
  const segment = totalRange / numElements;
  const getRange = (i) => [
    0.70 + i * segment,
    0.70 + (i + 1) * segment,
  ];

  // Ground floor doors
  const [door1Start, door1End] = getRange(0);
  const door1Opacity = useTransform(scrollYProgress, [door1Start, door1End], [0, 1]);
  const door1Y = useTransform(scrollYProgress, [door1Start, door1End], [20, 0]);

  const [door2Start, door2End] = getRange(1);
  const door2Opacity = useTransform(scrollYProgress, [door2Start, door2End], [0, 1]);
  const door2Y = useTransform(scrollYProgress, [door2Start, door2End], [20, 0]);

  const [door3Start, door3End] = getRange(2);
  const door3Opacity = useTransform(scrollYProgress, [door3Start, door3End], [0, 1]);
  const door3Y = useTransform(scrollYProgress, [door3Start, door3End], [20, 0]);

  // Upper floor windows
  const [window1Start, window1End] = getRange(3);
  const window1Opacity = useTransform(scrollYProgress, [window1Start, window1End], [0, 1]);
  const window1Y = useTransform(scrollYProgress, [window1Start, window1End], [20, 0]);

  const [window2Start, window2End] = getRange(4);
  const window2Opacity = useTransform(scrollYProgress, [window2Start, window2End], [0, 1]);
  const window2Y = useTransform(scrollYProgress, [window2Start, window2End], [20, 0]);

  const [window3Start, window3End] = getRange(5);
  const window3Opacity = useTransform(scrollYProgress, [window3Start, window3End], [0, 1]);
  const window3Y = useTransform(scrollYProgress, [window3Start, window3End], [20, 0]);

  return (
    <motion.g>
      {/* Ground Floor Doors */}
      <motion.rect
        x="165"
        y="315"
        width="190"
        height="135"
        fill="#555"
        stroke="#333"
        strokeWidth="2"
        style={{ opacity: door1Opacity, translateY: door1Y }}
      />
      <motion.rect
        x="170"
        y="320"
        width="90"
        height="130"
        fill="#add8e6"
        stroke="#333"
        strokeWidth="2"
        style={{ opacity: door2Opacity, translateY: door2Y }}
      />
      <motion.rect
        x="260"
        y="320"
        width="90"
        height="130"
        fill="#add8e6"
        stroke="#333"
        strokeWidth="2"
        style={{ opacity: door3Opacity, translateY: door3Y }}
      />

      {/* Upper Floor Windows with Sparkles */}
      <motion.g
        style={{
          opacity: window1Opacity,
          translateY: window1Y,
        }}
      >
        <rect x="50" y="50" width="140" height="170" fill="#add8e6" stroke="#333" strokeWidth="2" />
        {/* Sparkles now inherit window positioning properly */}
        <g transform="translate(50, 50)">
          <SparkleGroup width={140} height={170} count={1} />
        </g>
      </motion.g>

      <motion.g
        style={{
          opacity: window2Opacity,
          translateY: window2Y,
        }}
      >
        <rect x="190" y="50" width="140" height="170" fill="#add8e6" stroke="#333" strokeWidth="2" />
        <g transform="translate(190, 50)">
          <SparkleGroup width={140} height={170} count={1} />
        </g>
      </motion.g>

      <motion.g
        style={{
          opacity: window3Opacity,
          translateY: window3Y,
        }}
      >
        <rect x="330" y="50" width="140" height="170" fill="#add8e6" stroke="#333" strokeWidth="2" />
        <g transform="translate(330, 50)">
          <SparkleGroup width={140} height={170} count={1} />
        </g>
      </motion.g>
    </motion.g>
  );
};


/**
 * SidewalkBlock Component: A single sidewalk block.
 */
const SidewalkBlock = ({
  scrollYProgress,
  blockStart,
  blockEnd,
  x,
  y,
  blockWidth,
  blockHeight,
}) => {
  const opacity = useTransform(scrollYProgress, [blockStart, blockEnd], [0, 1]);
  const translateY = useTransform(scrollYProgress, [blockStart, blockEnd], [20, 0]);

  return (
    <motion.rect
      x={x}
      y={y}
      width={blockWidth}
      height={blockHeight}
      fill="#ccc"        // Light grey sidewalk block
      stroke="#999"      // Slightly darker border
      strokeWidth="1"
      style={{
        opacity,
        translateY,
      }}
    />
  );
};

/**
 * SidewalkBlocks Component: Renders a row of sidewalk blocks that animate from left to right.
 */
const SidewalkBlocks = ({ scrollYProgress }) => {
  const sidewalkStart = 0.85;
  const sidewalkEnd = 0.90;
  const numBlocks = 5; // Adjust the number of blocks as desired.
  const blockSegment = (sidewalkEnd - sidewalkStart) / numBlocks;
  const sidewalkWidth = 500; // Full viewport width (matches the SVG viewBox)
  const blockWidth = sidewalkWidth / numBlocks;
  const blockHeight = 60;
  const offsetY = 455;   // Positioned directly below the foundation.

  return (
    <>
      {Array.from({ length: numBlocks }).map((_, i) => {
        const blockStart = sidewalkStart + i * blockSegment;
        const blockEnd = blockStart + blockSegment;
        const x = i * blockWidth;
        return (
          <SidewalkBlock
            key={`sidewalk-block-${i}`}
            scrollYProgress={scrollYProgress}
            blockStart={blockStart}
            blockEnd={blockEnd}
            x={x}
            y={offsetY}
            blockWidth={blockWidth}
            blockHeight={blockHeight}
          />
        );
      })}
    </>
  );
};

/**
 * FinalTouches: Renders the sidewalk blocks and potted plants.
 * The pot and leafy plant fade in together after the sidewalk animation.
 */
const FinalTouches = ({ scrollYProgress }) => {
  // We change the mapping range to [0.90, 0.95] so that once full opacity is reached, it stays.
  const plantOpacity = useTransform(scrollYProgress, [0.85, 0.90], [0, 1]);

  return (
    <motion.g>
      {/* Sidewalk Blocks Animation */}
      <SidewalkBlocks scrollYProgress={scrollYProgress} />

      {/* Left Potted Plant */}
      <g>
        {/* Pot (static aside from fading in) */}
        <motion.rect 
          x="70" 
          y="460" 
          width="60" 
          height="25" 
          fill="#8B4513" 
          style={{ opacity: plantOpacity }}
        />
        {/* Animated Leafy Plant with thinner leaves */}
        <motion.g
          style={{ opacity: plantOpacity, transformOrigin: "100px 450px" }}
          animate={{ rotate: [-2, 2, -2] }}
          transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
        >
          {/* Center leaf: a narrow, tall ellipse */}
          <ellipse cx="100" cy="450" rx="8" ry="30" fill="#228B22" />
          {/* Left leaf */}
          <ellipse cx="90" cy="440" rx="7" ry="25" fill="#2E8B57" />
          {/* Right leaf */}
          <ellipse cx="110" cy="440" rx="7" ry="25" fill="#2E8B57" />
          {/* Additional top leaf */}
          <ellipse cx="100" cy="430" rx="7" ry="25" fill="#2E8B57" />
        </motion.g>
      </g>

      {/* Right Potted Plant */}
      <g>
        {/* Pot (static aside from fading in) */}
        <motion.rect 
          x="370" 
          y="460" 
          width="60" 
          height="25" 
          fill="#8B4513" 
          style={{ opacity: plantOpacity }}
        />
        {/* Animated Leafy Plant with thinner leaves */}
        <motion.g
          style={{ opacity: plantOpacity, transformOrigin: "400px 450px" }}
          animate={{ rotate: [2, -2, 2] }}
          transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
        >
          <ellipse cx="400" cy="450" rx="8" ry="30" fill="#228B22" />
          <ellipse cx="390" cy="440" rx="7" ry="25" fill="#2E8B57" />
          <ellipse cx="410" cy="440" rx="7" ry="25" fill="#2E8B57" />
          <ellipse cx="400" cy="430" rx="7" ry="25" fill="#2E8B57" />
        </motion.g>
      </g>
    </motion.g>
  );
};

const useMobileDetect = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return isMobile;
};

const getTimingRanges = (isMobile) => ({
  preConstruction: isMobile 
    ? { start: 0.2, mid: 0.3, fadeStart: 0.35, end: 0.4 }
    : { start: 0.1, mid: 0.2, fadeStart: 0.25, end: 0.3 },
  construction: isMobile
    ? { start: 0.35, mid: 0.45, fadeStart: 0.5, end: 0.55 }
    : { start: 0.25, mid: 0.35, fadeStart: 0.4, end: 0.45 },
  contracting: isMobile
    ? { start: 0.5, mid: 0.6, fadeStart: 0.65, end: 0.7 }
    : { start: 0.4, mid: 0.5, fadeStart: 0.55, end: 0.6 },
  finishing: isMobile
    ? { start: 0.65, mid: 0.75, fadeStart: 0.8, end: 0.85 }
    : { start: 0.55, mid: 0.65, fadeStart: 0.7, end: 0.75 },
  quality: isMobile
    ? { start: 0.8, mid: 0.9, fadeStart: 0.95, end: 1.0 }
    : { start: 0.7, mid: 0.8, fadeStart: 0.85, end: 0.9 }
});

const PhaseDescriptions = ({ scrollYProgress }) => {
  const isMobile = useMobileDetect();
  const ranges = getTimingRanges(isMobile);

  const textPreConstruction = useTransform(
    scrollYProgress,
    [ranges.preConstruction.start, ranges.preConstruction.mid, ranges.preConstruction.fadeStart, ranges.preConstruction.end],
    [1, 1, 0, 0]
  );
  const textConstructionManagement = useTransform(
    scrollYProgress,
    [ranges.construction.start, ranges.construction.mid, ranges.construction.fadeStart, ranges.construction.end],
    [0, 1, 1, 0]
  );
  const textGeneralContracting = useTransform(
    scrollYProgress,
    [ranges.contracting.start, ranges.contracting.mid, ranges.contracting.fadeStart, ranges.contracting.end],
    [0, 1, 1, 0]
  );
  const textFinishSelection = useTransform(
    scrollYProgress,
    [ranges.finishing.start, ranges.finishing.mid, ranges.finishing.fadeStart, ranges.finishing.end],
    [0, 1, 1, 0]
  );
  const textQualityControl = useTransform(
    scrollYProgress,
    [ranges.quality.start, ranges.quality.mid, ranges.quality.fadeStart, ranges.quality.end],
    [0, 1, 1, 0]
  );

  return (
    <div className="phase-descriptions">
      <div className="phase-content">
        <motion.div 
          className="phase-item"
          style={{ opacity: textPreConstruction }}
        >
          <h3>Pre-Construction Planning</h3>
          <p>Conduct feasibility studies, establish budgets and timelines, and select key project participants.</p>
        </motion.div>

        <motion.div 
          className="phase-item"
          style={{ opacity: textConstructionManagement }}
        >
          <h3>Construction Management</h3>
          <p>Coordinate architects, engineers, and subcontractors while overseeing the entire build process.</p>
        </motion.div>

        <motion.div 
          className="phase-item"
          style={{ opacity: textGeneralContracting }}
        >
          <h3>General Contracting</h3>
          <p>Serve as the primary contractor responsible for executing the project from start to finish.</p>
        </motion.div>

        <motion.div 
          className="phase-item"
          style={{ opacity: textFinishSelection }}
        >
          <h3>Finish Selection Services</h3>
          <p>Collaborate with owners to choose materials and finishes that bring the vision to life.</p>
        </motion.div>

        <motion.div 
          className="phase-item"
          style={{ opacity: textQualityControl }}
        >
          <h3>Quality Control &amp; Assurance</h3>
          <p>Ensure that every aspect of construction meets rigorous standards and specifications.</p>
        </motion.div>
      </div>
    </div>
  );
};

const DetailedMultiUnitBuildingWithPreconstruction = () => {
  const isMobile = useMobileDetect();
  const containerRef = useRef(null);
  
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start 15%", "end end"]
  });

  return (
    <div ref={containerRef} className="building-animation-container">
      <OurProcess />
      <div className="sticky-container">
        <div className="animation-content">
          <svg width="500" height="800" viewBox="0 0 500 500">
            <PreConstruction scrollYProgress={scrollYProgress} />
            <Foundation scrollYProgress={scrollYProgress} />
            <Framing scrollYProgress={scrollYProgress} />
            <Wrapping scrollYProgress={scrollYProgress} />
            <WindowsAndDoors scrollYProgress={scrollYProgress} />
            <FinalTouches scrollYProgress={scrollYProgress} />
          </svg>
          <PhaseDescriptions scrollYProgress={scrollYProgress} />
        </div>
      </div>
      <div className="spacer"></div>
    </div>
  );
};

export default DetailedMultiUnitBuildingWithPreconstruction;


