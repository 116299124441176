// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HeroSection from './components/HeroSection';
import MissionStatement from './components/MissionStatement';
import Gallery from './components/Gallery';
import ContactPage from './components/ContactPage';
import Header from './components/Header';
import Footer from './components/Footer';
import BuildingAnimation from './components/BuildingAnimation';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <HeroSection />
              <MissionStatement />
              <BuildingAnimation />
              <Gallery />
              <Footer />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Header />
              <ContactPage />
              <Footer />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;